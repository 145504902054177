<template>
  <div class="content mt-11">
    <div
      class="brq pl-2 mb-8"
      :style="`border-left: ${theme.midColor} 6px solid;`"
    >
      <div class="sub-title">核心例句</div>
    </div>
    <v-expansion-panels v-model="panel" multiple flat>
      <v-expansion-panel
        :style="`border-left: 6px ${theme.midColor}80 solid;background-color: #eeeeee50;`"
        class="page-wordlist"
        v-for="(item, i) in words"
        :key="i"
        dense
      >
        <v-expansion-panel-header
          class="page-wordlist-head d-flex justify-space-between"
        >
          <span>{{ item.word }}</span
          ><span
            class="mx-3 cut-words"
            style="
              color: #7a7a7a;
              font-weight: 300;
              text-align: right;
              font-size: 0.8rem;
            "
            >{{ item.mean }}</span
          >
        </v-expansion-panel-header>
        <v-expansion-panel-content class="page-wordlist-content">
          <div class="d-flex justify-space-between align-center">
            <div class="left mr-5">
              <div>例句：<span v-html="item.sentence"></span></div>
              <div class="mt-1">
                翻译：<span v-html="item.translate"></span>
              </div>
            </div>

            <v-btn
              x-small
              dense
              fab
              style="
                max-height: 1.2rem;
                max-width: 1.2rem;
                margin-right: -0.2rem;
              "
              @click="searchWord(item.word)"
            >
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider class="mt-11 mb-12"></v-divider>
  </div>
</template>

<script>
export default {
  name: "pageWords",
  props: {
    theme: {
      type: Object,
      default: () => ({}),
    },
    words: {
      type: Array,
      default: () => [],
    },
    panel: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    searchWord(word) {
      this.$emit("searchWord", word);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 0 1.5rem;
  text-align: justify;
}
.page-wordlist {
  font-family: sans-serif;
  .page-wordlist-head {
    letter-spacing: 0.15rem;
    font-weight: bold;
    color: #606060;
    font-size: 0.9rem;
  }
  .page-wordlist-content {
    font-weight: 300;
    font-size: 0.85rem;
  }
}
</style>
